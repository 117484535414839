<template>
    <div class="basicInformationWrap">
        <div class="basicInformation content">
            <div class="rightView">
                <el-form :label-position="labelPosition" :rules="rules" ref="userInfor" class="formContent" :model="userInfor">
                    <!-- <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item :label="$t('oa.account')" prop="accountNo">
                                <el-input v-model="userInfor.accountNo" :placeholder="$t('oa.enterAccount')" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row> -->
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item :label="$t('oa.userName')" prop="username">
                                <el-input v-model="userInfor.username" :placeholder="$t('oa.userName')" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item :label="$t('oa.email')" prop="email">
                                <el-input v-model="userInfor.email" :placeholder="$t('oa.enteremail')" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="16">
                            <el-form-item :label="$t('oa.mobile')" prop="mobile">
                                <el-input v-model="userInfor.mobile" :placeholder="$t('oa.enterMobile')" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('oa.gender')" prop="gender">
                                <el-select v-model="userInfor.gender" :placeholder="$t('oa.choiceGender')" disabled>
                                    <el-option :label="$t('oa.male')" :value="1"></el-option>
                                    <el-option :label="$t('oa.female')" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item :label="$t('oa.idNo')" prop="idNo">
                                <el-input v-model="userInfor.idNo" :placeholder="$t('oa.enteridNo')" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item :label="$t('oa.birth')" prop="birthday">
                                <el-date-picker v-model="userInfor.birthday" value-format="yyyy年MM月dd" type="date" style="width:100%" :placeholder="$t('oa.choiceBirth')" disabled>
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="$t('oa.Marital')" prop="martialStatus">
                                <el-select v-model="userInfor.martialStatus" :placeholder="$t('oa.choiceMarital')" disabled>
                                    <el-option :label="$t('oa.married')" :value="1"></el-option>
                                    <el-option :label="$t('oa.unmarried')" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item :label="$t('oa.mail')" prop="mailingAddress" class="inputAddress">
                        <el-row :gutter="20">
                            <el-col :span="16">
                                <el-input v-model="userInfor.mailingAddress" :placeholder="$t('oa.enterResidential')" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.mcity" :placeholder="$t('oa.city')" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.mcounty" :placeholder="$t('oa.postCode')" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.mstate" :placeholder="$t('oa.state')" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.mpostCode" :placeholder="$t('oa.county')" disabled></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item :label="$t('oa.Residential')" prop="residentialAddress" class="inputAddress">
                        <el-row :gutter="20">
                            <el-col :span="16">
                                <el-input v-model="userInfor.residentialAddress" :placeholder="$t('oa.entermail')" class="flex1" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.rcity" :placeholder="$t('oa.city')" class="flex1" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.rpostCode" :placeholder="$t('oa.postCode')" class="flex1" disabled></el-input>
                            </el-col>

                            <el-col :span="8">
                                <el-input v-model="userInfor.rstate" :placeholder="$t('oa.state')" class="flex1" disabled></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-input v-model="userInfor.rcounty" :placeholder="$t('oa.county')" class="flex1" disabled></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item> -->
                    <!-- <el-button type="primary" class="submit" :loading="userInforLoading" @click="submitForm('userInfor')">{{$t('oa.preservation')}}</el-button> -->
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import url from "@/api/url.js";
export default {
    name: "",
    data() {
        return {
            labelPosition: "top",
            userInforLoading: false,
            userInfor: {
                username: "",
                email: "",
                mobile: "",
                gender: "",
                birthday: "",
                idNo: "",
                martialStatus: "",
                accountNo: "",

                mcity: "",
                mcounty: "",
                mstate: "",
                mpostCode: "",
                mailingAddress: "",
                residentialAddress: "",
                rcity: "",
                rcounty: "",
                rstate: "",
                rpostCode: "",
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                email: [
                    { required: true, message: "请输入邮箱", trigger: "blur" },
                ],
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                ],
                gender: [
                    { required: true, message: "请选择性别", trigger: "blur" },
                ],
                birthday: [
                    {
                        required: true,
                        message: "请选择出生日期",
                        trigger: "blur",
                    },
                ],
                idNo: [
                    {
                        required: true,
                        message: "请输入身份证号",
                        trigger: "blur",
                    },
                ],
                martialStatus: [
                    {
                        required: true,
                        message: "请选择婚姻状态",
                        trigger: "blur",
                    },
                ],
                accountNo: [
                    { required: true, message: "请输入账号", trigger: "blur" },
                ],
            },
        };
    },
    created() {
        this.getInfor();
        this.initLang();
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
    },
    methods: {
        initLang() {
            this.rules.username[0].message = this.$t("oa.enterUserName");
            this.rules.email[0].message = this.$t("oa.enteremail");
            this.rules.mobile[0].message = this.$t("oa.enterMobile");
            this.rules.gender[0].message = this.$t("oa.choiceGender");
            this.rules.birthday[0].message = this.$t("oa.choiceBirth");
            this.rules.idNo[0].message = this.$t("oa.enteridNo");
            this.rules.martialStatus[0].message = this.$t("oa.choiceMarital");
            this.rules.accountNo[0].message = this.$t("oa.enterAccount");
            // this.rules.residentialAddress[0].message = this.$t("oa.enterResidential");
            // this.rules.mailingAddress[0].message = this.$t("oa.entermail");
        },
        getInfor() {
            this.$axios.get(url.userInfor).then((res) => {
                this.userInfor.userId = res.user.userId;
                this.userInfor.username = res.user.username;
                this.userInfor.email = res.user.email;
                this.userInfor.mobile = res.user.mobile;
                this.userInfor.gender = res.user.gender;
                this.userInfor.birthday = res.user.birthday;
                this.userInfor.idNo = res.user.idNo;
                this.userInfor.martialStatus = res.user.martialStatus;
                this.userInfor.accountNo = res.user.accountNo;
                this.userInfor.residentialAddress = res.user.residentialAddress;
                this.userInfor.rpostCode = res.user.rpostCode;
                this.userInfor.rcity = res.user.rcity;
                this.userInfor.rcounty = res.user.rcounty;
                this.userInfor.rstate = res.user.rstate;
                this.userInfor.mailingAddress = res.user.mailingAddress;
                this.userInfor.mcity = res.user.mcity;
                this.userInfor.mcounty = res.user.mcounty;
                this.userInfor.mstate = res.user.mstate;
                this.userInfor.mpostCode = res.user.mpostCode;
            });
        },
        submitForm(formName) {
            if (this.userInforLoading) return;

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.userInforLoading = true;
                    this.$axios
                        .post(url.upDateUserInfor, {
                            ...this.userInfor,
                        })
                        .then((res) => {
                            this.$message({
                                type: "success",
                                message: this.$t("oa.saveSuccess"),
                            });
                        })
                        .finally(() => {
                            this.userInforLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.basicInformationWrap {
    width: 100%;
    .basicInformation {
        display: flex;
        width: 625px;
    }
    .leftView {
        width: 345px;
        padding: 0 30px;
        height: 700px;
        position: relative;
        background-image: linear-gradient(#fbfbfb, #fff);
        .leftTitle {
            padding-top: 80px;
            padding-bottom: 30px;
            border-bottom: 1px solid #919191;
            display: flex;
            align-items: center;
            h3 {
                font-size: 30px;
                font-weight: normal;
            }
            img {
                margin-right: 20px;
            }
        }
        .contentTips {
            padding-top: 40px;
            line-height: 30px;
            font-size: 14px;
            color: #666;
            .iicon {
                text-align: center;
            }
        }
        .leftImgBG {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .rightView {
        flex: 1;
    }
    .formContent {
        padding:20px 40px;
        min-height: 710px;
        /deep/.el-form-item__label {
            font-weight: bold;
            color: #000;
        }
        /deep/.el-form-item__content {
            display: flex;
            flex-wrap: wrap;
            .flex1 {
                flex: 60%;
            }
        }
        .inputAddress {
            /deep/.el-input__inner {
                margin-bottom: 10px;
            }
        }
        .submit {
            margin: 30px 0px;
            width: 100%;
            background-color: #305A9E;
            border-color: #305A9E;
        }
    }
}
</style>